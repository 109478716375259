import { combineReducers } from "redux";
import auth from "./auth/auth";
import category from "./master/category";
import tag from "./master/tag";
import parentCategory from "./master/parentCategory";
import blog from "./blog/blog";
import bdaContact from "./bdaContact/bdaContact";



export const reducers = combineReducers({
   auth,
   parentCategory,
   category,
   tag,
   blog,
   bdaContact

});
