import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  category: [],
  categoryBySlug:[],
  state: "idle",
  error: null,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CATEGORY:
      return {
        ...state,
        category: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.CHECK_CATEGORY_SLUG:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case actionTypes.DELETE_CATEGORY:
      console.log(state);
      const categoryIdToDelete = action.payload;
      return {
        ...state,
        category: state.category.data.filter(
          (category) => category.id !== categoryIdToDelete
        ),
      };
      case actionTypes.GET_CATEGORY_BY_SLUG:
        return {
          ...state,
          categoryBySlug: action.payload,
        };

    default:
      return state;
  }
};

export default categoryReducer;
