import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  tag: [],
  tagBySlug:[],
  state: "idle",
  error: null,
};

export const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TAG:
      return {
        ...state,
        tag: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.CHECK_TAG_SLUG:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.GET_TAG:
      return {
        ...state,
        tag: action.payload,
      };
      case actionTypes.GET_TAG_BY_SLUG:
        return {
          ...state,
          tagBySlug: action.payload,
        };
    case actionTypes.DELETE_TAG:
      // console.log(state)
      const tagIdToDelete = action.payload;
      return {
        ...state,
        tag: state.tag.data.filter((tag) => tag._id !== tagIdToDelete),
      };

    default:
      return state;
  }
};

export default tagReducer;
