import * as api from "../../api";
import {
  ADD_BLOG,
  CHECK_BLOG_SLUG,
  GET_BLOG,
  GET_BLOG_BY_SLUG,
  UPDATE_BLOG,
  DELETE_BLOG,
  PUBLISH_BLOG,
  DELETE_BLOG_IMAGE,
  ADD_FEATURED_IMAGE,
  ADD_ADDITIONAL_IMAGE,
  DELETE_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
  DELETE_BLOG_TAG,
  UPDATE_BLOG_TAG
} from "../../constants/actionTypes";

export const addBlog = (formData) => async (dispatch) => {
  try {
    //  console.log(formData)
    const id = formData.get("id");
    const response = await api.addBlog(formData, id);
    dispatch({ type: ADD_BLOG, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const checkBlogSlug = (updatedData) => async (dispatch) => {
  try {
    const { data } = await api.checkBlogSlug(updatedData);
    dispatch({ type: CHECK_BLOG_SLUG, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBlog = (params) => async (dispatch) => {
  try {
    const { data } = await api.getBlog(params);
    dispatch({ type: GET_BLOG, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBlogBySlug = (slug) => async (dispatch) => {
  try {
    const { data } = await api.getBlogBySlug(slug);
    dispatch({ type: GET_BLOG_BY_SLUG, payload: data });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateBlog = (updatedData) => async (dispatch) => {
  try {
    const { data } = await api.updateBlog(updatedData);
    dispatch({ type: UPDATE_BLOG, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  try {
    const response = await api.deleteBlog(id);
    dispatch({ type: DELETE_BLOG, payload: id });
    return response.data;
  } catch (error) {
    console.error("Error deleting blog", error);
    throw error;
  }
};

export const publishBlog = (publishData) => async (dispatch) => {
  try {
    const { data } = await api.publishBlog(publishData);
    dispatch({ type: PUBLISH_BLOG, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteBlogImage = (id) => async (dispatch) => {
  try {
    const response = await api.deleteBlogImage(id);
    dispatch({ type: DELETE_BLOG_IMAGE, payload: id });
    return response.data;
  } catch (error) {
    console.error("Error deleting blog image", error);
    throw error;
  }
};



export const addFeaturedImage = (formData) => async (dispatch) => {
  try {
    const id = formData.get("id");
    const response = await api.addFeaturedImage(formData, id);
    dispatch({ type: ADD_FEATURED_IMAGE, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addAdditionalImage = (formData) => async (dispatch) => {
  try {
    const id = formData.get("id");
    const response = await api.addAdditionalImage(formData, id);
    dispatch({ type: ADD_ADDITIONAL_IMAGE, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteBlogCategory = (data) => async (dispatch) => {
  try {
    const response = await api.deleteBlogCategory(data);
    dispatch({ type: DELETE_BLOG_CATEGORY, payload: response.data });
    return response.data;
  } catch (error) {
    console.error("Error deleting blog category", error);
    throw error;
  }
};

export const updateBlogCategory = (updatedData) => async (dispatch) => {
  try {
    const { data } = await api.updateBlogCategory(updatedData);
    dispatch({ type: UPDATE_BLOG_CATEGORY, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteBlogTag = (data) => async (dispatch) => {
  try {
    const response = await api.deleteBlogTag(data);
    dispatch({ type: DELETE_BLOG_TAG, payload: response.data });
    return response.data;
  } catch (error) {
    console.error("Error deleting blog tag", error);
    throw error;
  }
};

export const updateBlogTag = (updatedData) => async (dispatch) => {
  try {
    const { data } = await api.updateBlogTag(updatedData);
    dispatch({ type: UPDATE_BLOG_TAG, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};