import React, { useState } from "react";
import Tag from "./Tag";
import ShowTag from "./ShowTag";

const TagManagement = () => {
  const [activeTab, setActiveTab] = useState("add-tag");

  return (
    <div>
      <ul
        role="tablist"
        className="w-full flex flex-col text-center sm:flex-row lg:justify-start"
      >
        <li role="presentation" className="focus-visible:outline-none">
          <a
            onClick={() => setActiveTab("add-tag")}
            className={`cursor-pointer font-space-grotesk block appearance-none px-5 py-4 custom-tab ${
              activeTab === "add-tag" ? "custom-active-tab" : ""
            }`}
          >
            Add Tag
          </a>
        </li>
        <li role="presentation" className="focus-visible:outline-none">
          <a
            onClick={() => setActiveTab("get-tag")}
            className={`cursor-pointer font-space-grotesk block appearance-none px-5 py-4 custom-tab ${
              activeTab === "get-tag" ? "custom-active-tab" : ""
            }`}
          >
            Show
          </a>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="mt-0">
        {activeTab === "add-tag" && (
          <div>
            <Tag />
          </div>
        )}

        {activeTab === "get-tag" && (
          <div>
            <ShowTag />
          </div>
        )}
      </div>
    </div>
  );
};

export default TagManagement;
