import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  blog: [],
  blogBySlug: [],
  state: "idle",
  error: null,
};

export const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BLOG:
      return {
        ...state,
        blog: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.CHECK_BLOG_SLUG:
    case actionTypes.UPDATE_BLOG:
    case actionTypes.PUBLISH_BLOG:
    case actionTypes.ADD_FEATURED_IMAGE:
    case actionTypes.ADD_ADDITIONAL_IMAGE:
    case actionTypes.DELETE_BLOG_CATEGORY:
    case actionTypes.UPDATE_BLOG_CATEGORY:
      case actionTypes.DELETE_BLOG_TAG:
        case actionTypes.UPDATE_BLOG_TAG:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.GET_BLOG:
      return {
        ...state,
        blog: action.payload,
      };
    case actionTypes.GET_BLOG_BY_SLUG:
      return {
        ...state,
        blogBySlug: action.payload,
      };
    case actionTypes.DELETE_BLOG:
      const blogIdToDelete = action.payload;
      return {
        ...state,
        blog: state.blog.data.filter((blog) => blog.id !== blogIdToDelete),
      };
    case actionTypes.DELETE_BLOG_IMAGE:
      console.log(state);
      const imageIdToDelete = action.payload;
      return {
        ...state,
        blog: state.blogBySlug.data.images.map((blog) =>
          blog.id === imageIdToDelete
            ? { ...blog, imageUrl: null } // Or remove the blog entry depending on your structure
            : blog
        ),
      };

    default:
      return state;
  }
};

export default blogReducer;
