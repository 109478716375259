import * as api from "../../api";
import {
  ADD_PARENT_CATEGORY,
  GET_PARENT_CATEGORY,
  CHECK_PARENT_SLUG_CATEGORY,
  DELETE_PARENT_CATEGORY,
  EDIT_PARENT_CATEGORY,
  GET_PARENT_CATEGORY_BY_SLUG
} from "../../constants/actionTypes";

export const addParentCategory = (formData) => async (dispatch) => {
  try {
    const response = await api.addParentCategory(formData);
    dispatch({ type: ADD_PARENT_CATEGORY, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getParentCategory = () => async (dispatch) => {
  try {
    const { data } = await api.getParentCategory();
    dispatch({ type: GET_PARENT_CATEGORY, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const checkParentSlugCategory = (updatedData) => async (dispatch) => {
  try {
    const { data } = await api.checkParentSlugCategory(updatedData);
    dispatch({ type: CHECK_PARENT_SLUG_CATEGORY, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteParentCategory = (id) => async (dispatch) => {
  try {
    const response = await api.deleteParentCategory(id);
    dispatch({ type: DELETE_PARENT_CATEGORY, payload: id });
    return response.data;
  } catch (error) {
    console.error("Error deleting parent category:", error);
    throw error;
  }
};

export const editParentCategory = (formData) => async (dispatch) => {
  try {
    const slug = formData.get("slug1");
    const { data } = await api.editParentCategory(formData,slug);
    dispatch({ type: EDIT_PARENT_CATEGORY, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getParentCategoryBySlug = (slug) => async (dispatch) => {
  try {
    const { data } = await api.getParentCategoryBySlug(slug);
    dispatch({ type: GET_PARENT_CATEGORY_BY_SLUG, payload: data });
  } catch (error) {
    console.log(error);
    throw error;
  }
};