import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:5000/api/",
  baseURL: "https://law-wheels.onrender.com/api/",
});

api.interceptors.request.use(
  (req) => {
    if (localStorage.getItem("profile")) {
      req.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("profile")).authToken
      }`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = (userInfo) => api.post(`employee/login`, userInfo);

export const addParentCategory = (formData) => {
  return api.post(`blogger/parentCategories`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addCategory = (formData) => {
  return api.post(`blogger/categories`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCategory = (params) =>
  api.get(`blogger/categories`, { params });

export const getParentCategory = () => api.get(`blogger/parentCategories`);

export const checkParentSlugCategory = (categoryInfo) =>
  api.put(`blogger/parentCategorySlug`, categoryInfo);

export const checkCategorySlug = (categoryInfo) =>
  api.put(`blogger/categorySlug`, categoryInfo);

export const addTag = (tagData) => api.post(`blogger/tag`, tagData);

export const checkTagSlug = (tagInfo) => api.put(`blogger/tagSlug`, tagInfo);

export const getTag = () => api.get(`blogger/tag`);

export const deleteTag = (id) => api.delete(`blogger/tag/${id}`);

export const addBlog = (formData) => {
  return api.post(`blogger/blog`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getBlog = (params) => api.get(`blogger/blog`, { params });

export const checkBlogSlug = (tagInfo) => api.put(`blogger/blogSlug`, tagInfo);
export const deleteParentCategory = (id) =>
  api.delete(`blogger/parentCategories/${id}`);
export const deleteCategory = (id) => api.delete(`blogger/categories/${id}`);

export const editParentCategory = (formData, slug) => {
  formData.delete("slug1");
  return api.put(`blogger/parentCategories/${slug}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getParentCategoryBySlug = (slug) =>
  api.get(`blogger/parentCategories/${slug}`);

export const editCategory = (formData, slug) => {
  formData.delete("slug1");
  return api.put(`blogger/categories/${slug}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCategoryBySlug = (slug) =>
  api.get(`blogger/categories/${slug}`);

export const getTagBySlug = (slug) => api.get(`blogger/tag/${slug}`);

export const editTag = ({ slug1, ...updatedData }) => {
  return api.put(`blogger/tag/${slug1}`, updatedData);
};

export const getBlogBySlug = (slug) => api.get(`blogger/blog/${slug}`);

export const updateBlog = ({ id, ...updateData }) => {
  return api.put(`blogger/blog/${id}`, updateData);
};
export const deleteBlog = (id) => api.delete(`blogger/blog/${id}`);
export const publishBlog = ({ id, ...publishData }) => {
  return api.put(`blogger/publish/${id}`, publishData);
};

export const deleteBlogImage = (id) => api.delete(`blogger/blogPic/${id}`);

export const addFeaturedImage = (formData, id) => {
  formData.delete("id");
  return api.put(`blogger/featuredPic/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addAdditionalImage = (formData, id) => {
  formData.delete("id");
  return api.put(`blogger/additionalPic/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getBDAContact = (params) =>
  api.get(`BDA/contactLeads`, { params });
export const getBDAContactById = (id) => api.get(`BDA/contactLeads/${id}`);

export const addLeadLogs = (logsData) => {
  return api.post(`BDA/leadsLog`, logsData);
};

export const getBDANotification = () => api.get(`BDA/notification`);

export const deleteBlogCategory = ({slug,...data}) => api.put(`blogger/deleteCategoryFromBlog/${slug}`,data);
export const updateBlogCategory = (data) => api.put(`blogger/addCategoryToBlog`,data);

export const deleteBlogTag = ({slug,...data}) => api.put(`blogger/deleteTagFromBlog/${slug}`,data);
export const updateBlogTag= (data) => api.put(`blogger/addTagToBlog`,data);