import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkCategorySlug,
  editCategory,
  getCategoryBySlug,
} from "../../../actions/master/category";
import { getParentCategory } from "../../../actions/master/parentCategory";

const EditCategory = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [slugError, setSlugError] = useState("");
  const [pCategory, setPCategory] = useState([]);
  const [value, setValue] = useState({
    name: "",
    slug: "",
    description: "",
    parentCategory: null,
  });

  const { categoryBySlug } = useSelector((state) => state.category);

  useEffect(() => {
    if (slug) {
      dispatch(getCategoryBySlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (categoryBySlug && categoryBySlug.data) {
      setValue({
        name: categoryBySlug.data.name,
        slug: categoryBySlug.data.slug,
        description: categoryBySlug.data.description,
        parentCategory: categoryBySlug.data.pCategory.name,
      });
    }
  }, [dispatch, categoryBySlug]);

  // console.log(value)

  const generateSlug = (name) => {
    if (!name) return "";

    return name
      .toLowerCase() // Convert to lowercase
      .trim() // Trim leading and trailing spaces
      .replace(/[^\w\s-]/g, "") // Remove special characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters long")
      .required("Name is required"),
    parentCategory: Yup.object()
      .nullable()
      .required("Please select a parent category"),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getParentCategory());
        // console.log(res);
        if (res.success) {
          setPCategory(res.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const options = pCategory?.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const handleSubmit = async (values) => {
    if (slugError) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("slug", values.slug);
      formData.append("slug1", slug);
      formData.append("pCategoryId", values.parentCategory.value);
      if (image) {
        formData.append("CategoryPic", image);
      }
      if (values.description) {
        formData.append("description", values.description);
      }

      const res = await dispatch(editCategory(formData));
      if (res.success) {
        toast.success(res.message);
        navigate("/blogger/category");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const checkSlugAvailability = debounce(async (slug) => {
    try {
      const res = await dispatch(checkCategorySlug({ slug }));
      setSlugError("");
    } catch (error) {
      setSlugError(error?.response?.data?.message || "Something went wrong!");
    }
  }, 2000);

  const defaultValue = (options, value) => {
    // console.log("value here", value);
    return options ? options.find((option) => option.label === value) : "";
  };

  return (
    <Formik
      initialValues={value}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="intro-y mt-8 flex items-center">
            <h2 className="mr-auto text-lg font-medium">Edit Category</h2>
          </div>
          <div className="mt-5 grid grid-cols-12 gap-6">
            <div className="intro-y col-span-12">
              <div className="intro-y box p-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="post-form-3"
                      className="inline-block font-space-grotesk mb-2"
                    >
                      Parent Categories
                    </label>
                    <Field name="parentCategory">
                      {({ field }) => (
                        <Select
                          id="tag-select"
                          options={options}
                          value={defaultValue(options, values.parentCategory)}
                          onChange={(option) =>
                            setFieldValue("parentCategory", option)
                          }
                          classNamePrefix="tag-select"
                          className={`w-full ${
                            errors.parentCategory && touched.parentCategory
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="parentCategory"
                      component="div"
                      className="text-red-500 mt-1 text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="name" className="inline-block mb-2">
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={values.name}
                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary ${
                        errors.name && touched.name ? "border-red-500" : ""
                      }`}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("name", value);
                        // setFieldValue("slug", generateSlug(value));
                        const generatedSlug = generateSlug(value);
                        setFieldValue("slug", generatedSlug);
                        checkSlugAvailability(generatedSlug);
                      }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 mt-1 text-sm"
                    />
                  </div>

                  <div>
                    <label htmlFor="slug" className="inline-block mb-2">
                      Slug
                    </label>
                    <Field
                      id="slug"
                      name="slug"
                      type="text"
                      value={values.slug}
                      placeholder="Enter Slug"
                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary ${
                        errors.slug && touched.slug ? "border-red-500" : ""
                      }`}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("slug", value);
                        checkSlugAvailability(value); // Check slug availability
                      }}
                    />

                    {slugError && (
                      <div className="text-red-500 mt-1 text-sm">
                        {slugError}
                      </div>
                    )}
                  </div>
                  {/* Image Upload Field */}
                  <div>
                    <label htmlFor="image" className="inline-block mb-2">
                      Upload Image
                    </label>
                    <input
                      id="image"
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={(event) =>
                        setImage(event.currentTarget.files[0])
                      }
                      className={`w-full text-sm border-slate-200 shadow-sm p-2 border rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary`}
                    />
                  </div>

                  {/* Description Field */}
                  <div>
                    <label htmlFor="description" className="inline-block mb-2">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      value={values.description}
                      placeholder="Write your text"
                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                      style={{ height: "100px" }}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="mt-5 text-right">
                  <button
                    disabled={loading}
                    type="submit"
                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 bg-primary border-primary text-white w-24"
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditCategory;
