import * as api from "../../api";
import { ADD_TAG, CHECK_TAG_SLUG, GET_TAG,DELETE_TAG, EDIT_TAG, GET_TAG_BY_SLUG} from "../../constants/actionTypes";

export const addTag = (tagData) => async (dispatch) => {
    try {
     const response = await api.addTag(tagData);
      dispatch({ type: ADD_TAG, payload:response.data  });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  export const checkTagSlug = (updatedData) => async (dispatch) => {
    try {
      const { data } = await api.checkTagSlug(updatedData);  
      dispatch({ type: CHECK_TAG_SLUG, payload: data });
      return data;
    } catch (error) {
      console.error(error);  
      throw error; 
    }
  };

  export const getTag = () => async (dispatch) => {
    try {
      const { data } = await api.getTag();
      dispatch({ type: GET_TAG, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  export const deleteTag = (id) => async (dispatch) => {
    try {
      const response = await api.deleteTag(id);
      dispatch({ type: DELETE_TAG, payload: id });
      return response.data;
    } catch (error) {
      console.error("Error deleting video:", error);
      throw error;
    }
  };


  export const editTag = (updatedData) => async (dispatch) => {
    try {
      const { data } = await api.editTag(updatedData);
      dispatch({ type: EDIT_TAG, payload: data });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  export const getTagBySlug = (slug) => async (dispatch) => {
    try {
      const { data } = await api.getTagBySlug(slug);
      dispatch({ type: GET_TAG_BY_SLUG, payload: data });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };