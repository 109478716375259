import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Loader from "../../shared/Loader";
import Pagination from "../pagination/Pagination";
import { deleteBlog, getBlog, publishBlog } from "../../actions/blog/blog";

const ViewBlog = () => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          limit: itemsPerPage,
        };
        const res = await dispatch(getBlog(params));
        console.log(res);
        if (res.success) {
          setRecords(res.data);
          setTotalPages(res.totalPage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, currentPage, itemsPerPage]);

  // console.log(totalPages)

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePublish = async (id, status) => {
    try {
      setLoading(true); // Start the loading state
      // Dispatch the publish action (you should have a publishBlog action in your redux)
      const res = await dispatch(publishBlog({ id, status }));

      if (res.success) {
        toast.success(res.message);
        // Optionally refresh or update records after publishing/unpublishing
        setRecords(
          records.map((record) =>
            record.id === id ? { ...record, status } : record
          )
        );
      } else {
        toast.error(
          res.message || `Failed to ${status.toLowerCase()} the blog`
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false); // End the loading state
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await dispatch(deleteBlog(id));
      if (res.success) {
        toast.success(res.message);
        // Refresh the records after deletion
        setRecords(records.filter((record) => record.id !== id));
      } else {
        toast.error("Failed to delete the tag.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        {" "}
        <Loader />
      </div>
    );
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so +1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  console.log(records);
  return (
    <div>
      <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table
          data-tw-merge=""
          className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]"
        >
          <thead data-tw-merge="" className="">
            <tr data-tw-merge="" className="">
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
              >
                SNO
              </th>
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
              >
                IMAGE
              </th>
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
              >
                TITLE
              </th>
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                SLUG
              </th>
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                STATUS
              </th>

              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                PUBLISH DATE
              </th>
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {records?.map((record, index) => {
              return (
                <tr data-tw-merge="" className="intro-x" key={record.id}>
                  <td
                    data-tw-merge=""
                    className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                  >
                    {index + 1}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-5 py-3 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                  >
                    <div className="flex">
                      <div className="image-fit zoom-in h-10 w-10">
                        <img
                          data-placement="top"
                          title=""
                          src={
                            record.images.find(
                              (img) => img.fieldName === "FeaturedPic"
                            )?.url
                          }
                          alt="preview"
                          className="tooltip cursor-pointer rounded-full shadow-[0px_0px_0px_2px_#fff,_1px_1px_5px_rgba(0,0,0,0.32)] dark:shadow-[0px_0px_0px_2px_#3f4865,_1px_1px_5px_rgba(0,0,0,0.32)]"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    data-tw-merge=""
                    className="title cursor-pointer px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                    title={record.title} /* Show full title in tooltip */
                  >
                    {record.title.length > 30
                      ? `${record.title.substring(0, 30)}...`
                      : record.title}{" "}
                  </td>

                  <td
                    data-tw-merge=""
                    className="blog-slug cursor-pointer px-5 py-3 font-space-grotesk  border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                    title={record.slug} 
                  >
                    {record.slug.length > 30
                      ? `${record.slug.substring(0, 30)}...`
                      : record.slug}{" "}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-5 py-3 font-space-grotesk  border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                  >
                    {record.status}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                  >
                    {formatDate(record.publishDate)}
                  </td>
                  <td
                    data-tw-merge=""
                    className="px-5 py-3 border-b dark:border-darkmode-300 box w-56 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400"
                  >
                    <div className="flex items-center justify-center">
                      <a className="mr-3 flex items-center" href="#">
                        <Link to={`/blogger/edit-blog/${record.slug}`}>
                          <div className="stroke-1.5 mr-2 h-4 w-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-pencil"
                            >
                              <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                              <path d="m15 5 4 4" />
                            </svg>
                          </div>
                        </Link>
                      </a>
                      <a
                        className="flex items-center text-danger"
                        data-tw-toggle="modal"
                        data-tw-target="#delete-confirmation-modal"
                        onClick={() => handleDelete(record.id)}
                      >
                        <div className="stroke-1.5 mr-1 h-4 w-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-trash-2"
                          >
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>
                      </a>

                      {record.status === "Draft" ||
                      record.status === "Unpublish" ? (
                        <a
                          className="ml-3 flex items-center text-success"
                          onClick={() => handlePublish(record.id, "Published")}
                        >
                          <div className="stroke-1.5 mr-1 h-4 w-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-upload-cloud"
                            >
                              <polyline points="16 16 12 12 8 16" />
                              <line x1="12" y1="12" x2="12" y2="21" />
                              <path d="M20.39 18.39A5 5 0 0 0 18 9H16.71a7 7 0 0 0-13.42 0H2a5 5 0 0 0 2.39 9.39" />
                            </svg>
                          </div>
                        </a>
                      ) : record.status === "Published" ? (
                        <a
                          className="ml-3 flex items-center text-warning"
                          onClick={() => handlePublish(record.id, "Unpublish")}
                        >
                          <div className="stroke-1.5 mr-1 h-4 w-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-x-circle"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <line x1="15" y1="9" x2="9" y2="15" />
                              <line x1="9" y1="9" x2="15" y2="15" />
                            </svg>
                          </div>
                        </a>
                      ) : null}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <!-- END: Data List --> */}
      {/* <!-- BEGIN: Pagination --> */}
      {records?.length > 0 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      ) : null}
    </div>
  );
};

export default ViewBlog;
