import React, { useState } from "react";
import AddParentCategory from "./AddParentCategory";
import ViewParentCategory from "./ViewParentCategory";


const ParentCategoryTab = () => {
  const [activeTab, setActiveTab] = useState("add");

  return (
    <div>
      <ul
        role="tablist"
        className="w-full flex flex-col text-center sm:flex-row lg:justify-start"
      >
        <li role="presentation" className="focus-visible:outline-none">
          <a
            onClick={() => setActiveTab("add")}
            className={`cursor-pointer font-space-grotesk block appearance-none px-5 py-4 custom-tab ${
              activeTab === "add" ? "custom-active-tab" : ""
            }`}
          >
            Add
          </a>
        </li>
        <li role="presentation" className="focus-visible:outline-none">
          <a
            onClick={() => setActiveTab("view")}
            className={`cursor-pointer font-space-grotesk block appearance-none px-5 py-4 custom-tab ${
              activeTab === "view" ? "custom-active-tab" : ""
            }`}
          >
            View
          </a>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="mt-0">
        {activeTab === "add" && (
          <div>
            <AddParentCategory />
          </div>
        )}

        {activeTab === "view" && (
          <div>
          <ViewParentCategory />
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentCategoryTab;
