import React, { useState } from "react";
import Category from "./Category";
import ViewCategory from "./ViewCategory";

const CategoryTab = () => {
  const [activeTab, setActiveTab] = useState("add");

  return (
    <div>
      <ul
        role="tablist"
        className="w-full flex flex-col text-center sm:flex-row lg:justify-start"
      >
        <li role="presentation" className="focus-visible:outline-none">
          <a
            onClick={() => setActiveTab("add")}
            className={`cursor-pointer font-space-grotesk block appearance-none px-5 py-4 custom-tab ${
              activeTab === "add" ? "custom-active-tab" : ""
            }`}
          >
            Add
          </a>
        </li>
        <li role="presentation" className="focus-visible:outline-none">
          <a
            onClick={() => setActiveTab("view")}
            className={`cursor-pointer font-space-grotesk block appearance-none px-5 py-4 custom-tab ${
              activeTab === "view" ? "custom-active-tab" : ""
            }`}
          >
            View
          </a>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="mt-0">
        {activeTab === "add" && (
          <div>
            <Category />
          </div>
        )}

        {activeTab === "view" && (
          <div>
            <ViewCategory />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryTab;
