import React from 'react'

const BDADashboard = () => {
  return (
    <div>
      BDA Dashboard
    </div>
  )
}

export default BDADashboard
