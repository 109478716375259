
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { checkCategorySlug, editCategory, getCategoryBySlug } from "../../../actions/master/category";

import { checkTagSlug, editTag, getTagBySlug } from "../../../actions/master/tag";

const EditTag = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [slugError, setSlugError] = useState("");
  const [value, setValue] = useState({
    name: "",
    slug: "",
    description: "",
  });

  const { tagBySlug } = useSelector((state) => state.tag);

  useEffect(() => {
    if (slug) {
      dispatch(getTagBySlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (tagBySlug && tagBySlug.data) {
      setValue({
        name: tagBySlug.data.name,
        slug: tagBySlug.data.slug,
        description: tagBySlug.data.description,
      });
    }
  }, [dispatch, tagBySlug]);

  // console.log(categoryBySlug)

  const generateSlug = (name) => {
    if (!name) return "";

    return name
      .toLowerCase() // Convert to lowercase
      .trim() // Trim leading and trailing spaces
      .replace(/[^\w\s-]/g, "") // Remove special characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters long")
      .required("Name is required"),
  });

  const handleSubmit = async (values) => {
    if (slugError) return;
    setLoading(true);
    try {
        const payload = {
            name: values.name,
            slug: values.slug,
            slug1:slug
          };
    
          if (values.description) {
            payload.description = values.description;
          }

      const res = await dispatch(editTag(payload));
      if (res.success) {
        toast.success(res.message);
        navigate("/blogger/tag");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const checkSlugAvailability = debounce(async (slug) => {
    try {
      const res = await dispatch(checkTagSlug({ slug }));
      setSlugError("");
    } catch (error) {
      setSlugError(error?.response?.data?.message || "Something went wrong!");
    }
  }, 2000);

  return (
    <Formik
      initialValues={value}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="intro-y mt-8 flex items-center">
            <h2 className="mr-auto text-lg font-medium">
              Edit Tag
            </h2>
          </div>
          <div className="mt-5 grid grid-cols-12 gap-6">
            <div className="intro-y col-span-12">
              <div className="intro-y box p-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              
                  <div>
                    <label htmlFor="name" className="inline-block mb-2">
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={values.name}
                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary ${
                        errors.name && touched.name ? "border-red-500" : ""
                      }`}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("name", value);
                        // setFieldValue("slug", generateSlug(value));
                        const generatedSlug = generateSlug(value);
                        setFieldValue("slug", generatedSlug);
                        checkSlugAvailability(generatedSlug);
                      }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 mt-1 text-sm"
                    />
                  </div>

                  <div>
                    <label htmlFor="slug" className="inline-block mb-2">
                      Slug
                    </label>
                    <Field
                      id="slug"
                      name="slug"
                      type="text"
                      value={values.slug}
                      placeholder="Enter Slug"
                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary ${
                        errors.slug && touched.slug ? "border-red-500" : ""
                      }`}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("slug", value);
                        checkSlugAvailability(value); // Check slug availability
                      }}
                    />

                    {slugError && (
                      <div className="text-red-500 mt-1 text-sm">
                        {slugError}
                      </div>
                    )}
                  </div>
            
                  <div>
                    <label htmlFor="description" className="inline-block mb-2">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      value={values.description}
                      placeholder="Write your text"
                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                      style={{ height: "100px" }}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="mt-5 text-right">
                  <button
                    disabled={loading}
                    type="submit"
                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 bg-primary border-primary text-white w-24"
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditTag;
