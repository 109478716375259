import React, { useState, useEffect } from "react";
import {
  ChevronDown,
  // Activity,
  // Eye,
  FileText,
  // X,
  Image,
  AlignLeft,
  Code,
  // Tag,
} from "lucide-react";
import Loader from "../../shared/Loader";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import {
  addAdditionalImage,
  addFeaturedImage,
  deleteBlogCategory,
  deleteBlogImage,
  deleteBlogTag,
  getBlogBySlug,
  updateBlog,
  updateBlogCategory,
  updateBlogTag,
} from "../../actions/blog/blog";
import { getTag } from "../../actions/master/tag";
import { getParentCategory } from "../../actions/master/parentCategory";
import { getCategory } from "../../actions/master/category";
import { checkBlogSlug } from "../../actions/blog/blog";

const EditBlog = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [activeTab, setActiveTab] = useState("content");
  const [isEditingCategories, setIsEditingCategories] = useState(false);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [additionalImage, setAdditionalImage] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [slugError, setSlugError] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [postDate, setPostDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  // const [additionalImages, setAdditionalImages] = useState([]);
  const [value, setValue] = useState({
    title: "",
    slug: "",
    editorContent: "",
    readTime: "",
    metaTitle: "",
  });

  const { blogBySlug } = useSelector((state) => state.blog);

  useEffect(() => {
    if (slug) {
      dispatch(getBlogBySlug(slug));
    }
  }, [dispatch, slug]);

  // console.log(blogBySlug);

  const combineDateAndTime = () => {
    if (startDate && selectedTime) {
      const combinedDateTime = new Date(startDate);
      const timeHours = selectedTime.getHours();
      const timeMinutes = selectedTime.getMinutes();
      const timeSeconds = selectedTime.getSeconds();

      combinedDateTime.setHours(timeHours, timeMinutes, timeSeconds, 0);

      return combinedDateTime.toISOString(); // Returns ISO formatted string
    }
    return "";
  };

  useEffect(() => {
    const combined = combineDateAndTime();
    setPostDate(combined);
  }, [startDate, selectedTime]);

  const setDateAndTimeFromPublishDate = (publishDate) => {
    if (!publishDate) return;

    const dateObj = new Date(publishDate);

    // Extract the date without the time
    const startDate = new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate()
    );

    // Extract the time (hours and minutes)
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    // Create a date object for the time
    const selectedTime = new Date(0, 0, 0, hours, minutes); // Arbitrary date, just to store time

    return { startDate, selectedTime };
  };

  useEffect(() => {
    if (blogBySlug && blogBySlug.data) {
      setSelectedTags(blogBySlug.data.blogTag_juction);
      setSelectedCategories(blogBySlug.data.blogCategory_juction);
      const { startDate, selectedTime } = setDateAndTimeFromPublishDate(
        blogBySlug.data.publishDate
      );

      setStartDate(startDate); // Set startDate from parsed date
      setSelectedTime(selectedTime);
      setId(blogBySlug.data.id);
      setSelectedImage(
        blogBySlug.data.images.find((img) => img.fieldName === "FeaturedPic")
      );
      setAdditionalImage(
        blogBySlug.data.images.filter(
          (img) => img.fieldName === "AdditionalPic"
        )
      );
      setIsPublished(blogBySlug.data.status === "Published" ? true : false);
      setValue({
        title: blogBySlug.data.title,
        slug: blogBySlug.data.slug,
        editorContent: blogBySlug.data.content,
        readTime: blogBySlug.data.readTime,
        metaTitle: blogBySlug.data.metaTag,
        description: blogBySlug.data.description,
      });
    }
  }, [dispatch, blogBySlug]);

  const handleUpdateCategory = async () => {
    // console.log("Edit button clicked");
    try {
      setIsEditing(true);
      if (selectedCategories && selectedCategories.length > 0) {
        const transformedCategories = selectedCategories.map((category) => ({
          id: category.id,
          slug: category.name,
        }));

        const categoryPayload = {
          categorys: transformedCategories,
          blogId: id,
        };

        const res = await dispatch(updateBlogCategory(categoryPayload));
        if (res.success) {
          toast.success(res.message);
        } else {
          toast.error("Failed to update the category.");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsEditing(false);
      setIsEditingCategories(false);
    }
  };

  const handleUpdateTag = async () => {
    // console.log("Edit button clicked");
    try {
      setIsEditing(true);
      if (selectedTags && selectedTags.length > 0) {
        const transformedTags = selectedTags.map((tag) => ({
          id: tag.id,
          slug: tag.name,
        }));

        const tagPayload = {
          tags: transformedTags,
          blogId: id,
        };

        const res = await dispatch(updateBlogTag(tagPayload));
        if (res.success) {
          toast.success(res.message);
        } else {
          toast.error("Failed to update the category.");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsEditing(false);
      setIsEditingCategories(false);
    }
  };

  const handleEditCategories = () => {
    setIsEditingCategories(!isEditingCategories);
  };
  const handleEditTags = () => {
    setIsEditingTags(!isEditingTags);
  };
  const generateSlug = (name) => {
    if (!name) return "";

    return name
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await dispatch(deleteBlogImage(id));
      if (res.success) {
        toast.success(res.message);
        // setSelectedImage(null);
      } else {
        toast.error("Failed to delete the tag.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async (slug) => {
    try {
      setLoading(true);
      const data = { slug, blogId: id };
      const res = await dispatch(deleteBlogCategory(data));
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error("Failed to delete the tag.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTag = async (slug) => {
    try {
      setLoading(true);
      const data = { slug, blogId: id };
      const res = await dispatch(deleteBlogTag(data));
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error("Failed to delete the tag.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  // console.log(additionalImage);
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, "Title must be at least 3 characters long")
      .required("Title is required"),
    editorContent: Yup.string().required("Content is required."),
    readTime: Yup.string().required("Read time is required"),
    // postDate: Yup.date().required("Post date is required"),
    // selectedImage: Yup.mixed().required("Image is required"),
  });

  const handleSubmit = async (values) => {
    if (slugError) return;
    setLoading(true);
    try {
      const payload = {
        title: values.title,
        slug: values.slug,
        content: values.editorContent,
        readTime: values.readTime,
        publishDate: postDate,
        status: isPublished ? "Published" : "Draft",
        id,
      };

      if (values.description) {
        payload.description = values.description;
      }

      if (values.metaTitle) {
        payload.metaTag = values.metaTitle;
      }

      const res = await dispatch(updateBlog(payload));
      console.log(res);
      if (res.success) {
        toast.success(res.message);
        navigate("/blogger/post");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsPublished(event.target.checked);
  };

  // const handleTagChange = (option) => {
  //   setSelectedOption(option);
  // };

  const handleChange = (selectedOptions) => {
    setSelectedParent(selectedOptions);
  };

  // const handleChildChange = (selectedOptions) => {
  //   setSelectedCategories(selectedOptions);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getTag());
        if (res.success) {
          const data = res.data.map((item) => ({
            value: item.id,
            label: item.slug,
          }));
          setOptions(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  // console.log("options",options)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getParentCategory());
        if (res.success) {
          const data = res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setParentCategories(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchChildCategories = async () => {
      if (!selectedParent) return; // No need to fetch if no parent is selected
      try {
        setLoading(true);
        const res = await dispatch(getCategory());
        console.log(res);
        if (res.success) {
          const filteredCategories = res?.data?.filter((item) => {
            return (
              item.pCategory && item.pCategory.name === selectedParent?.label
            );
          });

          const data = filteredCategories.map((item) => ({
            value: item.id,
            label: item.slug,
          }));
          setCategories(data);
        }
      } catch (error) {
        console.error("Error fetching child categories:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchChildCategories();
  }, [dispatch, selectedParent]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const checkSlugAvailability = debounce(async (slug) => {
    try {
      const res = await dispatch(checkBlogSlug({ slug }));
      setSlugError(""); // Clear error if slug is available
    } catch (error) {
      setSlugError(error?.response?.data?.message || "Something went wrong!");
    }
  }, 2000);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setSelectedImage(URL.createObjectURL(file)); // Preview the selected image
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("FeaturedPic", image);
      formData.append("id", id);
      const res = await dispatch(addFeaturedImage(formData));
      // console.log(res);
      if (res.success) {
        toast.success(res.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdditionalImage = async (event) => {
    const files = Array.from(event.target.files);
    // console.log(files);
    // setAdditionalImages(files);
    const newImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file), // Create a URL for the image preview
    }));

    // Check if adding these images would exceed the 20-image limit
    if (additionalImage.length + newImages.length > 20) {
      alert("You can only upload up to 20 images.");
    } else {
      setAdditionalImage((prevImages) => [...prevImages, ...newImages]);
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("AdditionalPic", file);
        });
      }
      formData.append("id", id);
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value); // This will print out each key-value pair
      // }
      const res = await dispatch(addAdditionalImage(formData));
      // console.log(res);
      if (res.success) {
        toast.success(res.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        {" "}
        <Loader />
      </div>
    );
  }
  // console.log(selectedCategories);
  return (
    <>
      <Formik
        initialValues={value}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div className="intro-y mt-8 flex items-center">
              <h2 className="mr-auto text-lg font-medium">Edit Blog</h2>
            </div>
            <div className="mt-5 grid grid-cols-12 gap-6">
              <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
                <div className="grid grid-cols-12 gap-6">
                  <div className="intro-y box col-span-12 2xl:col-span-6">
                    <div className="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-3">
                      <h2 className="mr-auto text-base font-medium">Content</h2>
                      <div
                        data-tw-merge=""
                        data-tw-placement="bottom-end"
                        className="dropdown relative ml-auto sm:hidden"
                      >
                        <button
                          data-tw-toggle="dropdown"
                          aria-expanded="false"
                          className="cursor-pointer block h-5 w-5"
                          tag="a"
                        >
                          <i
                            data-tw-merge=""
                            data-lucide="more-horizontal"
                            className="stroke-1.5 w-5 h-5 text-slate-500"
                          ></i>
                        </button>
                        <div
                          data-transition=""
                          data-selector=".show"
                          data-enter="transition-all ease-linear duration-150"
                          data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                          data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                          data-leave="transition-all ease-linear duration-150"
                          data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                          data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                          className="dropdown-menu absolute z-[9999] hidden"
                        >
                          <div
                            data-tw-merge=""
                            className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40"
                          >
                            <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                              <i
                                data-tw-merge=""
                                data-lucide="file"
                                className="stroke-1.5 mr-2 h-4 w-4"
                              ></i>
                              Download Excel
                            </a>
                          </div>
                        </div>
                      </div>
                      <button
                        disabled={loading}
                        type="submit"
                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 bg-primary border-primary text-white w-24"
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                    </div>
                    <div className="p-5">
                      <div className="intro-y mt-5 grid grid-cols-12 gap-5">
                        <div className="intro-y col-span-12 ">
                          <div>
                            <label
                              htmlFor="title"
                              className="inline-block mb-2"
                            >
                              Title
                            </label>
                            <Field
                              id="title"
                              name="title"
                              type="text"
                              value={values.title}
                              placeholder="Enter Title"
                              className={`disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 intro-y !box px-4 py-3 pr-10 ${
                                errors.title && touched.title
                                  ? "border-red-500"
                                  : ""
                              }`}
                              onChange={(e) => {
                                const { value } = e.target;
                                setFieldValue("title", value);
                                // setFieldValue("slug", generateSlug(value));
                                const generatedSlug = generateSlug(value);
                                setFieldValue("slug", generatedSlug);
                                checkSlugAvailability(generatedSlug);
                              }}
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="text-red-500 mt-1 text-sm"
                            />
                          </div>
                          <div className="mt-3">
                            <label htmlFor="slug" className="inline-block mb-2">
                              Slug
                            </label>
                            <Field
                              id="slug"
                              name="slug"
                              type="text"
                              value={values.slug}
                              placeholder="Enter Slug"
                              className={`w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary ${
                                errors.slug && touched.slug
                                  ? "border-red-500"
                                  : ""
                              }`}
                              onChange={(e) => {
                                const { value } = e.target;
                                setFieldValue("slug", value);
                                checkSlugAvailability(value); // Check slug availability
                              }}
                            />

                            {slugError && (
                              <div className="text-red-500 mt-1 text-sm">
                                {slugError}
                              </div>
                            )}
                          </div>
                          <div className="intro-y box mt-5 overflow-hidden">
                            <div>
                              <ul className="border-b w-full flex flex-col border-transparent bg-slate-200 dark:border-transparent dark:bg-darkmode-800 sm:flex-row">
                                <li
                                  id="content-tab"
                                  className="focus-visible:outline-none -mb-px"
                                >
                                  <button
                                    onClick={() => handleTabChange("content")}
                                    role="tab"
                                    type="button"
                                    className={`cursor-pointer appearance-none border border-transparent dark:text-slate-400 ${
                                      activeTab === "content"
                                        ? "dark:text-white bg-white font-medium dark:border-b-darkmode-600 text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent"
                                        : "hover:border-transparent hover:bg-transparent hover:text-slate-600 hover:dark:bg-transparent hover:dark:text-slate-300"
                                    } rounded-t-md dark:border-transparent flex items-center justify-center w-full px-0 py-0 sm:w-40 text-slate-500`}
                                  >
                                    <span
                                      className="tooltip font-space-grotesk cursor-pointer flex w-full items-center justify-center py-4"
                                      aria-controls="content"
                                      aria-selected={activeTab === "content"}
                                    >
                                      <FileText className="stroke-1.5 mr-2 h-4 w-4" />
                                      Content
                                    </span>
                                  </button>
                                </li>
                                <li
                                  id="meta-title-tab"
                                  className="focus-visible:outline-none -mb-px"
                                >
                                  <button
                                    onClick={() =>
                                      handleTabChange("meta-title")
                                    }
                                    role="tab"
                                    type="button"
                                    className={`cursor-pointer appearance-none border border-transparent dark:text-slate-400 ${
                                      activeTab === "meta-title"
                                        ? "dark:text-white bg-white font-medium dark:border-b-darkmode-600 text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent"
                                        : "hover:border-transparent hover:bg-transparent hover:text-slate-600 hover:dark:bg-transparent hover:dark:text-slate-300"
                                    } rounded-t-md dark:border-transparent flex items-center justify-center w-full px-0 py-0 sm:w-40 text-slate-500`}
                                  >
                                    <span
                                      className="tooltip font-space-grotesk cursor-pointer flex w-full items-center justify-center py-4"
                                      aria-controls="meta-title"
                                      aria-selected={activeTab === "meta-title"}
                                    >
                                      <Code className="stroke-1.5 mr-2 h-4 w-4" />
                                      Meta Title
                                    </span>
                                  </button>
                                </li>
                                <li
                                  id="description-tab"
                                  className="focus-visible:outline-none -mb-px"
                                >
                                  <button
                                    onClick={() =>
                                      handleTabChange("description")
                                    }
                                    type="button"
                                    role="tab"
                                    className={`cursor-pointer appearance-none border border-transparent dark:text-slate-400 ${
                                      activeTab === "description"
                                        ? "dark:text-white bg-white font-medium dark:border-b-darkmode-600 text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent"
                                        : "hover:border-transparent hover:bg-transparent hover:text-slate-600 hover:dark:bg-transparent hover:dark:text-slate-300"
                                    } rounded-t-md dark:border-transparent flex items-center justify-center w-full px-0 py-0 sm:w-40 text-slate-500`}
                                  >
                                    <span
                                      className="tooltip font-space-grotesk cursor-pointer flex w-full items-center justify-center py-4"
                                      aria-controls="description"
                                      aria-selected={
                                        activeTab === "description"
                                      }
                                    >
                                      <AlignLeft className="stroke-1.5 mr-2 h-4 w-4" />
                                      Description
                                    </span>
                                  </button>
                                </li>
                              </ul>

                              <div className="tab-content">
                                {activeTab === "content" && (
                                  <div
                                    id="content"
                                    role="tabpanel"
                                    type="button"
                                    aria-labelledby="content-tab"
                                    className="tab-pane active p-5"
                                  >
                                    <div className="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400">
                                      <div className="flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400 font-space-grotesk">
                                        <ChevronDown className="stroke-1.5 mr-2 h-4 w-4" />
                                        Text Content
                                      </div>
                                      <div className="mt-5">
                                        <div className="editor">
                                          <ReactQuill
                                            value={values.editorContent}
                                            onChange={(value) => {
                                              setFieldValue(
                                                "editorContent",
                                                value
                                              );
                                            }}
                                            className="h-35 rounded-lg"
                                            theme="snow"
                                            modules={{
                                              toolbar: [
                                                [
                                                  { header: "1" },
                                                  { header: "2" },
                                                  { font: [] },
                                                ],
                                                [
                                                  { list: "ordered" },
                                                  { list: "bullet" },
                                                ],
                                                ["bold", "italic", "underline"],
                                                ["link", "image"],
                                                ["clean"],
                                              ],
                                            }}
                                            formats={[
                                              "header",
                                              "font",
                                              "list",
                                              "bullet",
                                              "bold",
                                              "italic",
                                              "underline",
                                              "link",
                                              "image",
                                            ]}
                                          />
                                        </div>
                                        {touched.editorContent &&
                                          errors.editorContent && (
                                            <div className="text-red-500 mt-2">
                                              {errors.editorContent}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {activeTab === "meta-title" && (
                                  <div
                                    id="meta-title"
                                    role="tabpanel"
                                    type="button"
                                    aria-labelledby="meta-title-tab"
                                    className="tab-pane active p-5"
                                  >
                                    <div className="mt-5">
                                      <div className="editor">
                                        <ReactQuill
                                          value={values.metaTitle}
                                          onChange={(value) => {
                                            setFieldValue("metaTitle", value);
                                          }}
                                          className="h-35 rounded-lg"
                                          theme="snow"
                                          modules={{
                                            toolbar: [
                                              [
                                                { header: "1" },
                                                { header: "2" },
                                                { font: [] },
                                              ],
                                              [
                                                { list: "ordered" },
                                                { list: "bullet" },
                                              ],
                                              ["bold", "italic", "underline"],
                                              ["link", "image"],
                                              ["clean"],
                                            ],
                                          }}
                                          formats={[
                                            "header",
                                            "font",
                                            "list",
                                            "bullet",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "link",
                                            "image",
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {activeTab === "description" && (
                                  <div
                                    id="description"
                                    role="tabpanel"
                                    aria-labelledby="description-tab"
                                    className="tab-pane active p-5"
                                  >
                                    <Field
                                      as="textarea"
                                      id="description"
                                      name="description"
                                      value={values.description}
                                      placeholder="Write your text"
                                      className={`w-full text-sm border-slate-200 shadow-sm rounded-md p-2 placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary 
                      `}
                                      style={{ height: "100px" }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 flex flex-col-reverse lg:col-span-4 lg:block 2xl:col-span-3">
                <div className="intro-y box mt-2 lg:mt-0">
                  <div className="col-span-12 lg:col-span-4">
                    <div className="intro-y box p-5">
                      <div className="mt-3">
                        <label
                          data-tw-merge=""
                          for="post-form-2"
                          className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                        >
                          Read Time
                        </label>

                        <Field
                          name="readTime"
                          type="text"
                          value={values.readTime}
                          placeholder="Enter Time (eg. 2 minutes 30 seconds)"
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                        />
                        <ErrorMessage
                          name="readTime"
                          component="div"
                          className="text-red-500 mt-2"
                        />
                      </div>
                      <div className="mt-3">
                        <label className="">Post Date</label>
                        <DatePicker
                          placeholderText="Select Date"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setFieldValue("selectedDate", date);
                          }}
                          dateFormat="yyyy-MM-dd"
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                        />

                        <ErrorMessage
                          name="selectedDate"
                          component="div"
                          className="text-red-500 mt-2"
                        />
                      </div>
                      <div className="mt-3">
                        <label className="inline-block mb-2">Select Time</label>
                        <DatePicker
                          placeholderText="Select Time"
                          selected={selectedTime}
                          onChange={(time) => {
                            setSelectedTime(time);
                            setFieldValue("selectedTime", time);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                        />
                        <ErrorMessage
                          name="selectedTime"
                          component="div"
                          className="text-red-500 mt-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="mt-5 grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
          <div className="grid grid-cols-12 gap-6">
            <div className="intro-y box col-span-12 2xl:col-span-6">
              <div>
                <div className="mt-5 rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400">
                  <div className="flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400 font-space-grotesk">
                    <ChevronDown className="stroke-1.5 mr-2 h-4 w-4" />
                    Images
                  </div>
                  <div className="mt-5">
                    <div className="mt-3">
                      <label className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                        Upload Featured Image
                      </label>
                      <div className="rounded-md border-2 border-dashed pt-4 dark:border-darkmode-400">
                        <div className="flex flex-wrap">
                          {/* <div className="relative  ml-5 h-24 w-24 border-2 border-dashed border-gray-300 flex items-center justify-center cursor-pointer">
                            {selectedImage ? (
                              <div className="image-fit zoom-in relative h-24 w-24 cursor-pointer">
                                <img
                                  className="rounded-md "
                                  src={selectedImage}
                                  alt="selected"
                                />
                              </div>
                            ) : (
                              // Show plus sign if no image is selected
                              <span className="text-3xl text-gray-400">+</span>
                            )}

                            <input
                              type="file"
                              accept="image/*"
                              className="absolute inset-0 opacity-0 cursor-pointer"
                              onChange={(event) => {
                                // setFieldValue(
                                //   "selectedImage",
                                //   event.target.files[0]
                                // );
                                handleImageChange(event);
                              }}
                            />
                          </div> */}

                          <div className="relative ml-5 h-24 w-24 border-2 border-dashed border-gray-300 flex items-center justify-center cursor-pointer">
                            {selectedImage ? (
                              <div className="relative h-24 w-24">
                                {/* Image Container */}
                                <div className="relative h-full w-full">
                                  {/* Delete button */}
                                  <a
                                    className="flex items-center text-danger absolute top-0 right-0"
                                    data-tw-toggle="modal"
                                    data-tw-target="#delete-confirmation-modal"
                                    onClick={() =>
                                      handleDelete(selectedImage.id)
                                    }
                                  >
                                    <div className="stroke-1.5 m-1 h-4 w-4">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="lucide lucide-trash-2"
                                      >
                                        <path d="M3 6h18" />
                                        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                        <line x1="10" x2="10" y1="11" y2="17" />
                                        <line x1="14" x2="14" y1="11" y2="17" />
                                      </svg>
                                    </div>
                                  </a>
                                  {/* Display selected image */}
                                  <img
                                    className="rounded-md object-cover h-full w-full"
                                    src={selectedImage?.url}
                                    alt="selected"
                                  />
                                </div>
                              </div>
                            ) : (
                              // Show plus sign if no image is selected
                              <>
                                <span className="text-3xl text-gray-400">
                                  +
                                </span>
                                {/* Image upload input */}
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="absolute inset-0 opacity-0 cursor-pointer"
                                  onChange={(event) => {
                                    handleImageChange(event);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {/* <ErrorMessage
                                              name="selectedImage"
                                              component="div"
                                              className="text-red-500 ml-5"
                                            /> */}
                        <div className="relative flex cursor-pointer items-center px-4 pb-4">
                          <Image className="stroke-1.5 mr-2 h-4 w-4" />
                          <span className="mr-1 text-primary font-space-grotesk">
                            Upload additional image
                          </span>

                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleAdditionalImage}
                            className="absolute left-0 top-0 h-full opacity-0 cursor-pointer"
                          />
                        </div>
                        <div className=" grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 lg:m-2 gap-4">
                          {additionalImage.map((image, index) => (
                            <div
                              key={index}
                              className="relative h-24 w-24 overflow-hidden rounded-md border"
                            >
                              <a
                                className="flex items-center text-danger absolute top-0 right-0"
                                data-tw-toggle="modal"
                                data-tw-target="#delete-confirmation-modal"
                                onClick={() => handleDelete(image.id)}
                              >
                                <div className="stroke-1.5 m-1 h-4 w-4">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="lucide lucide-trash-2"
                                  >
                                    <path d="M3 6h18" />
                                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                    <line x1="10" x2="10" y1="11" y2="17" />
                                    <line x1="14" x2="14" y1="11" y2="17" />
                                  </svg>
                                </div>
                              </a>
                              <img
                                src={image.url}
                                alt={`selected-${index}`}
                                className="h-full w-full object-cover"
                              />
                            </div>
                          ))}
                        </div>

                        {/* Display message if the image count is maxed */}
                        {additionalImage.length >= 20 && (
                          <div className="mt-2 text-red-600">
                            You’ve reached the maximum of 20 images.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 flex flex-col-reverse lg:col-span-4 lg:block 2xl:col-span-3">
          <div className="intro-y box mt-2 lg:mt-0">
            <div className="col-span-12 lg:col-span-4">
              <div className="intro-y box p-5">
                <div className="mt-3">
                  <div className="flex items-center justify-between">
                    <label
                      data-tw-merge=""
                      className="inline-block font-space-grotesk mb-2 font-semibold "
                    >
                      Categories
                    </label>

                    <div className="flex space-x-2 ml-2 ">
                      {isEditingCategories && (
                        <button
                          className="text-green-500 hover:underline"
                          onClick={handleUpdateCategory}
                        >
                          {selectedCategories.length === 0 ? "Add" : "Edit"}
                        </button>
                      )}
                    </div>
                  </div>

                  {!isEditingCategories ? (
                    <div className="flex justify-between items-center">
                      {selectedCategories.map((category, index) => (
                        <div
                          key={category.id}
                          className="flex items-center mb-2"
                        >
                          {/* Display Category Name */}
                          <div className="mr-2">{category.name}</div>

                          {/* Delete Button for each category */}
                          <button
                            className="text-red-500 hover:underline"
                            onClick={() => handleDeleteCategory(category.slug)}
                          >
                            <div className="stroke-1.5 mr-1 h-4 w-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-trash-2"
                              >
                                <path d="M3 6h18" />
                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                <line x1="10" x2="10" y1="11" y2="17" />
                                <line x1="14" x2="14" y1="11" y2="17" />
                              </svg>
                            </div>
                          </button>
                        </div>
                      ))}
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={handleEditCategories}
                      >
                        {selectedCategories.length === 0 ? "Add" : "Edit"}
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="mt-3">
                        <label
                          data-tw-merge=""
                          for="post-form-3"
                          className="inline-block font-space-grotesk mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right"
                        >
                          Parent Categories
                        </label>
                        <Select
                          id="tag-select"
                          options={parentCategories}
                          onChange={handleChange}
                          classNamePrefix="tag-select"
                          className="w-full"
                        />
                      </div>
                      <div className="mt-3">
                        <div className="flex items-center justify-between">
                          <label
                            data-tw-merge=""
                            className="inline-block font-space-grotesk mb-2 font-semibold "
                          >
                            Categories
                          </label>

                          <div className="flex space-x-2 ml-2 ">
                            {isEditingCategories && (
                              <button
                                className="text-green-500 hover:underline"
                                onClick={handleUpdateCategory}
                              >
                                {selectedCategories.length === 0
                                  ? "Add"
                                  : "Edit"}
                              </button>
                            )}
                          </div>
                        </div>

                        <Select
                          isMulti
                          value={selectedCategories.map((cat) => ({
                            value: cat.id,
                            label: cat.name,
                          }))}
                          options={categories}
                          onChange={(selectedOptions) => {
                            setSelectedCategories(
                              selectedOptions.map((opt) => ({
                                id: opt.value,
                                name: opt.label,
                              }))
                            );
                          }}
                          classNamePrefix="tag-select"
                          className="w-full"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-3">
                  <div className="flex items-center justify-between">
                    <label
                      data-tw-merge=""
                      className="inline-block font-space-grotesk mb-2 font-semibold "
                    >
                      Tags
                    </label>

                    <div className="flex space-x-2 ml-2 ">
                      {isEditingTags && (
                        <button
                          className="text-green-500 hover:underline"
                          onClick={handleUpdateTag}
                        >
                          {selectedTags.length === 0 ? "Add" : "Edit"}
                        </button>
                      )}
                    </div>
                  </div>
                  {!isEditingTags ? (
                    <div className="flex justify-between items-center">
                      {selectedTags.map((tag, index) => (
                        <div key={tag.id} className="flex items-center mb-2">
                          <div className="mr-2">{tag.name}</div>

                          <button
                            className="text-red-500 hover:underline"
                            onClick={() => handleDeleteTag(tag.slug)}
                          >
                            <div className="stroke-1.5 mr-1 h-4 w-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-trash-2"
                              >
                                <path d="M3 6h18" />
                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                <line x1="10" x2="10" y1="11" y2="17" />
                                <line x1="14" x2="14" y1="11" y2="17" />
                              </svg>
                            </div>
                          </button>
                        </div>
                      ))}
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={handleEditTags}
                      >
                        {selectedTags.length === 0 ? "Add" : "Edit"}
                      </button>
                    </div>
                  ) : (
                    <Select
                      isMulti
                      value={selectedTags.map((tag) => ({
                        value: tag.id,
                        label: tag.name,
                      }))}
                      options={options}
                      onChange={(selectedOptions) => {
                        setSelectedTags(
                          selectedOptions.map((opt) => ({
                            id: opt.value,
                            name: opt.label,
                          }))
                        );
                      }}
                      classNamePrefix="tag-select"
                      className="w-full"
                    />
                  )}
                </div>

                <div
                  data-tw-merge=""
                  className="flex mt-3 flex-col items-start"
                >
                  <label
                    data-tw-merge=""
                    for="post-form-5"
                    className="cursor-pointer mb-2 ml-0 font-space-grotesk"
                  >
                    Published
                  </label>

                  <input
                    type="checkbox"
                    id="post-form-5"
                    checked={isPublished}
                    onChange={handleCheckboxChange}
                    className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50 w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlog;
