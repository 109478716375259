import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  contact: [],
  leadLogs:[],
  state: "idle",
  error: null,
};

export const bdaContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_LEAD_LOGS:
      return {
        ...state,
        leadLogs: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.BDA_CONTACT_LEAD:
      return {
        ...state,
        contact: action.payload,
      };

    default:
      return state;
  }
};

export default bdaContactReducer;
