import * as api from "../../api";
import {
  ADD_CATEGORY,
  CHECK_CATEGORY_SLUG,
  DELETE_CATEGORY,
  GET_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY_BY_SLUG,
} from "../../constants/actionTypes";

export const addCategory = (formData) => async (dispatch) => {
  try {
    const response = await api.addCategory(formData);
    dispatch({ type: ADD_CATEGORY, payload: response.data });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const checkCategorySlug = (updatedData) => async (dispatch) => {
  try {
    const { data } = await api.checkCategorySlug(updatedData);
    dispatch({ type: CHECK_CATEGORY_SLUG, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCategory = (params) => async (dispatch) => {
  try {
    const { data } = await api.getCategory(params);
    dispatch({ type: GET_CATEGORY, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    const response = await api.deleteCategory(id);
    dispatch({ type: DELETE_CATEGORY, payload: id });
    return response.data;
  } catch (error) {
    console.error("Error deleting parent category:", error);
    throw error;
  }
};

export const editCategory = (formData) => async (dispatch) => {
  try {
    const slug = formData.get("slug1");
    const { data } = await api.editCategory(formData, slug);
    dispatch({ type: EDIT_CATEGORY, payload: data });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCategoryBySlug = (slug) => async (dispatch) => {
  try {
    const { data } = await api.getCategoryBySlug(slug);
    dispatch({ type: GET_CATEGORY_BY_SLUG, payload: data });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
