import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../shared/Loader";
import Pagination from "../../pagination/Pagination";
import { deleteTag, getTag } from "../../../actions/master/tag";

const ShowTag = () => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Assume 10 pages for example
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          limit: itemsPerPage,
        };
        const res = await dispatch(getTag(params));
        if (res.success) {
          setRecords(res.data);
          setTotalPages(res.totalPage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, currentPage, itemsPerPage]);

  // console.log(totalPages)

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await dispatch(deleteTag(id));
      if (res.success) {
        toast.success(res.message);
        // Refresh the records after deletion
        setRecords(records.filter((record) => record.id !== id));
      } else {
        toast.error("Failed to delete the tag.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        {" "}
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table
          data-tw-merge=""
          className="w-full text-left -mt-2 border-separate border-spacing-y-[10px]"
        >
          <thead data-tw-merge="" className="">
            <tr data-tw-merge="" className="">
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
              >
                SNO
              </th>

              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0"
              >
                NAME
              </th>

              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                TAG SLUG
              </th>

              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                DESCRIPTION
              </th>
              <th
                data-tw-merge=""
                className="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 text-center"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {records?.map((record, index) => (
              <tr key={record.id} data-tw-merge="" className="intro-x">
                <td
                  data-tw-merge=""
                  className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                >
                  {index + 1}
                </td>
                <td
                  data-tw-merge=""
                  className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                >
                  {record.name}
                </td>

                <td
                  data-tw-merge=""
                  className="px-5 py-3 font-space-grotesk  border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                >
                  {record.slug}
                </td>
                <td
                  data-tw-merge=""
                  className="px-5 py-3 border-b font-space-grotesk  dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
                >
                  {record.description ? record.description : "-"}
                </td>
                <td
                  data-tw-merge=""
                  className="px-5 py-3 border-b dark:border-darkmode-300 box w-56 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div className="flex items-center justify-center">
                    <a className="mr-3 flex items-center" href="#">
                      <div className="stroke-1.5 mr-2 h-4 w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-pencil"
                        >
                          <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                          <path d="m15 5 4 4" />
                        </svg>
                      </div>
                      <Link to={`/blogger/edit-tag/${record.slug}`}>Edit</Link>
                    </a>
                    <a
                      className="flex items-center text-danger"
                      data-tw-toggle="modal"
                      data-tw-target="#delete-confirmation-modal"
                      onClick={() => handleDelete(record.id)}
                    >
                      <div className="stroke-1.5 mr-1 h-4 w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-trash-2"
                        >
                          <path d="M3 6h18" />
                          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                          <line x1="10" x2="10" y1="11" y2="17" />
                          <line x1="14" x2="14" y1="11" y2="17" />
                        </svg>
                      </div>
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <!-- END: Data List --> */}
      {/* <!-- BEGIN: Pagination --> */}
      {records?.length > 0 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      ) : null}
    </div>
  );
};

export default ShowTag;
