import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  parentCategory: [],
  categoryBySlug:[],
  state: "idle",
  error: null,
};

export const parentCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_PARENT_CATEGORY:
      return {
        ...state,
        parentCategory: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionTypes.GET_PARENT_CATEGORY:
      return {
        ...state,
        parentCategory: action.payload,
      };
    case actionTypes.CHECK_PARENT_SLUG_CATEGORY:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
      case actionTypes.EDIT_PARENT_CATEGORY:
        return {
          ...state,
          success: action.payload,
          error: null,
        };
    case actionTypes.DELETE_PARENT_CATEGORY:
      console.log(state);
      const categoryIdToDelete = action.payload;
      return {
        ...state,
        parentCategory: state.parentCategory.data.filter(
          (category) => category.id !== categoryIdToDelete
        ),
      };
      case actionTypes.GET_PARENT_CATEGORY_BY_SLUG:
        return {
          ...state,
          categoryBySlug: action.payload,
        };

    default:
      return state;
  }
};

export default parentCategoryReducer;
